import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoginComponent } from './login';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
// import { SpinnerOverlayComponent } from '../shared/spinner-overlay/spinner-overlay.component';
import { MatSelectModule } from '@angular/material/select';
import { SingUpComponent } from './singUp';
import { SrhkCoreLibraryComponent, SrhkCoreLibraryModule } from '@srhk/srhk-core-library';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatCardModule,
        MatInputModule,
        MatDialogModule,
        MatTableModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        SrhkCoreLibraryModule,
        RouterModule.forChild([
            { path: 'login', component: LoginComponent },
            { path: 'singUp', component: SingUpComponent },
        ])
    ],
    declarations: [
        LoginComponent,
        SingUpComponent,
        // SpinnerOverlayComponent,
    ],
    exports: [
        LoginComponent,
        SingUpComponent
    ]
})
export class AuthModule { }
