<div>
    <div class="news--ticker">
        <div class="container">
            <div class="title">
                <h2>Events:</h2>
            </div>

            <div class="news-updates--list">
                <ul class="nav">
                    <marquee behavior="scroll" onmouseover="this.stop();" onmouseout="this.start();" scrolldelay="100">
                        <li><img src="./assets/images/news-seperator.png"></li>
                        <li>
                            <h3 class="h3" style="font-size:16px; font-weight:bold;">
                                <a href="notice-news">
                                    <font color="red">Mantra Diksha for Devotees on 9 April, 2023</font>
                                </a>
                            </h3>
                        </li>
                        <li><img src="./assets/images/news-seperator.png"></li>
                        <li>
                            <h3 class="h3" style="font-size:16px; font-weight:bold;">
                                <a href="notice-news">
                                    <font color="red">Adiguru Shankaracharya Jayanti Celebration on Sunday, May 12, 2024
                                    </font>
                                </a>
                            </h3>
                        </li>
                        <li><img src="./assets/images/news-seperator.png"></li>

                        <li>
                            <h3 class="h3" style="font-size:16px; font-weight:bold;">
                                <a href="notice-news">
                                    <font color="red">Buddha Purnima Celebration on Thursday, May 23, 2024</font>
                                </a>
                            </h3>
                        </li>
                        <li><img src="./assets/images/news-seperator.png"></li>
                        <li>
                            <h3 class="h3" style="font-size:16px; font-weight:bold;">
                                <a href="notice-news">
                                    <font color="red">Guru Purnima Celebration on Sunday, July 21, 2024</font>
                                </a>
                            </h3>
                        </li>
                        <li><img src="./assets/images/news-seperator.png"></li>

                        <li>
                            <h3 class="h3" style="font-size:16px; font-weight:bold;">
                                <a href="notice-news">
                                    <font color="red">Mantra Diksha for Devotees on 21 July, 2024</font>
                                </a>
                            </h3>
                        </li>
                        <li><img src="./assets/images/news-seperator.png"></li>

                        <li>
                            <h3 class="h3" style="font-size:16px; font-weight:bold;">
                                <a href="notice-news">
                                    <font color="red">Shree Shree Kalipuja</font>
                                </a>
                            </h3>
                        </li>
                    </marquee>
                </ul>
            </div>

        </div>
    </div>
</div>
<app-inspiration-mission></app-inspiration-mission>