import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_URLS } from "../../srhk.environment";
import { UserSignUp } from "../../model/UserSignUp";


const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class SignUpService  {

  constructor(private http: HttpClient) { }
  
  findUserDetails(userId : string) : Observable<any> {

    let params: HttpParams = new HttpParams();
    params = params.set('userId', userId);
    return <any>this.http.get(APP_URLS.forget_password, { params: params, headers : headers });
  }
  singUp(body: UserSignUp): any {

    const http$ = this.http.post<any>(APP_URLS.oauthSignup, body, {});

    return new Promise<any>((resolve, _reject) => {
      http$.subscribe(
        res => { resolve(res); },
        err => { resolve(err); },
        () => console.log('HTTP request completed.')
      );
    });
  }
}

