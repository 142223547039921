import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APP_URLS } from "../srhk.environment";
import { BasicInfoUpdate } from "../model/User";
import { AddCashReceipt } from "../model/billCashReceipt";


const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

const options = {
  headers: headers,
};

@Injectable({
  providedIn: 'root'
})
export class BillPaymentService {

  constructor(private http: HttpClient) { }

  public addcdddCashReceipt(body: AddCashReceipt): any {
    return this.http.post<any>(APP_URLS.ADD_CASH_RECEIPT.replace("{ORDER_NUMBER}", body.orderNumber), body, options);
  }

  public addGuestCashReceipt(body: AddCashReceipt): any {
    return this.http.post<any>(APP_URLS.ADD_GUEST_CASH_RECEIPT, body, options);
  }

  public getCashReceipt(orderNumber: string): any {
    return this.http.get<any>(APP_URLS.GET_CASH_RECEIPT.replace("{ORDER_NUMBER}", orderNumber), options);
  }

  public getGuestCashReceipt(orderNumber: string): any {
    return this.http.get<any>(APP_URLS.GET_GUEST_CASH_RECEIPT.replace("{ORDER_NUMBER}", orderNumber), options);
  }

  

}

