import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { AuthContext } from '../../shared/service/auth.context';
import { ActivatedRoute, Route, Router } from '@angular/router';
// import { AuthService } from '../../shared/service/auth.service';
// import { TokenService } from '../../shared/service/TokenService';
// import { CommonCacheService } from '../../shared/service/CommonCacheService';
import { LoginService } from './login.service';
import { AuthContext, AuthService, CommonCacheService, TokenService } from '@srhk/srhk-core-library';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
  ],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });
  isFormSubmitted: boolean = false;
  showError: boolean = false;
  hide: boolean = true;
  _loaderShow = false;
  showForgetPasswordMsg = false;
  validationMsg = "";
  appType = "";
  donation = "for Donation";
  public validationErrors = {
    email: {
      'required': 'User name is required',
      'email': 'Invalid email',
    },
    password: {
      'required': 'Password is required',
    },
  };
  constructor(
    private authContext: AuthContext,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tokenService: TokenService,
    private commonCacheService: CommonCacheService,
    private loginService: LoginService
  ) {
    if (this.authContext.isAuthenticated) {
      this.router.navigate(['app'], { replaceUrl: true });
    }
  }

  ngOnInit(): void {
    this.buildForm();
    this.appType = this.tokenService.getAppId();
    console.log("------appType------", this.appType)

    let isFromDonation = this.tokenService.isFromDonation();
    if (isFromDonation !== "true") {
      this.donation = "";
    }
  }

  viewPassword() {
    this.hide = !this.hide;
  }

  singUp() {
    this.router.navigate(['auth/singUp'], { replaceUrl: true });
  }

  private buildForm(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }



  public login(): void {
    this.showError = false;
    const username: string = this.loginForm.get('email').value;
    const password: string = this.loginForm.get('password').value;
    this.isFormSubmitted = true;

    if (this.loginForm.valid) {
      this._loaderShow = true;
      this.tokenService.removeToken();
      this.tokenService.removeRefreshToken();

      //get AccessToken
      this.authService.getAccessToken(username, password).then(res => {
        if (res && res.jwt) {

          this.tokenService.saveToken(res.jwt);
          this.tokenService.saveRefreshToken(null);
          this.tokenService.saveAppId(this.appType);
          //get UserInfo after token
          this.authService.getUserInfo(res.jwt).then((userInfo: any) => {
            // get user resource after 

            if (userInfo) {
              this.tokenService.saveUserInfo(userInfo);
              this.commonCacheService.cache.loggedInUserInfo = userInfo;
              var homeResource = null; // set home dashboard
              var homeDashboardURL = this.tokenService.getHomeDashboardURL() || '/app/dashboard';
              if (homeResource && homeResource.length > 0 && homeResource[0]['rsname'] && homeResource[0]['rsname'].includes('-')) {
                const homeDashboard = homeResource[0]['rsname'].split('-');
                homeDashboardURL = homeDashboard.length > 1 ? homeDashboard[1] : '/app';
              }
              this.tokenService.setHomeDashboardURL(homeDashboardURL);
              this.authContext.setAuthenticated();
              this.router.navigate([homeDashboardURL], { replaceUrl: true });
              this._loaderShow = false;
              window.location.reload();
            }
          });
        } else {
          this._loaderShow = false;
          this.showError = true;
        }
      })
        .catch((err) => {
          console.log(err);
          this._loaderShow = false;
          this.showError = true;
        });
    }
  }

  setContext(): void {
    this._loaderShow = false;
    this.authContext.setAuthenticated();
    this.router.navigate(['app'], { replaceUrl: true });
  }

  getFullYear() {
    return new Date().getFullYear();
  }

  showHidePass() {
    this.hide = !this.hide;
  }

  forgetPassword() {
    this.showForgetPasswordMsg = false;
    this._loaderShow = true;
    this.validationMsg = "";

    const username: string = this.loginForm.get('email').value;

    if (!username) {
      this.showForgetPasswordMsg = true;
      this._loaderShow = false;
      this.validationMsg = "Please Enter a Valid Username";
      return;
    }

    this.loginService.findUserDetails(username).subscribe(userDetails => {
      this._loaderShow = false;
      this.showForgetPasswordMsg = true;
      this.validationMsg = "Reset Password has been Send to Registerd EmailId.";
    }, errorMessage => {
      console.error(errorMessage);
      this._loaderShow = false;
      this.showForgetPasswordMsg = true;
      this.validationMsg = errorMessage ? errorMessage.error.userMessages[0].messageTxt : []
    });
  }

}
