<div>
    <mat-card>
        <mat-card-content>
            <table mat-table [dataSource]="myBookinglist" matSort class="mat-elevation-z8">

                <ng-container matColumnDef="sno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.sno}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="orderNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>orderNumber</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.orderNumber">
                            {{element.orderNumber}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>firstName</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.firstName">
                            {{element.firstName}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>lastName</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.lastName">
                            {{element.lastName}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="formattedNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>formattedNumber</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.formattedNumber">
                            {{element.formattedNumber}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>email</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.email">
                            {{element.email}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.type">
                            {{element.type}}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.amount">
                            {{element.amount}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.status">
                            {{element.status}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.status">
                            {{element.date}}
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card-content>
    </mat-card>
    <br />
    <br />
</div>