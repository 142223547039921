import { Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthContext, ToastService, TokenService } from '@srhk/srhk-core-library';
import { RzpayBookAppointmentComponent } from '../rzpay-book-appointment/rzpay-book-appointment.component';

@Component({
  selector: 'app-inspiration-mission',
  templateUrl: './inspiration-mission.component.html',
  styleUrl: './inspiration-mission.component.css'
})
export class InspirationMissionComponent implements OnInit {

  readonly dialog = inject(MatDialog);
  public constructor(private router: Router,
    private route: ActivatedRoute,
    private authContext: AuthContext,
    private tokenService: TokenService,
    private toastService: ToastService
  ) {
    

  }

  ngOnInit(): void {

  }

  donation(purpose: string){
    // this.tokenService.setIsFromDonation('true');
    // this.tokenService.setDonationPurpose(purpose)
    // this.router.navigate(["/donation"])
    const dialogRef = this.dialog.open(RzpayBookAppointmentComponent, {
      hasBackdrop: false,
      data: {
        url: 'https://rzp.io/rzp/LqrDvNE'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/']);
    });
  }
}
