<div class="login-container">
  <mat-spinner *ngIf="_loaderShow" class="tp-margin">
  </mat-spinner>
  <div>
    <!-- <img class="wave" src="../../../assets/images/wave_blue.png"> -->
    <div class="container">
      <!-- <div class="img">
          <img src="../../../assets/images/bg_new.svg">
        </div> -->
      <div class="login-content">
        <form [formGroup]="signUpForm" *ngIf="signUpForm">
          <h3 class="product-sign-in-text">User Registration</h3>
          <p class="login-error-msg" *ngIf="showError">Invalid Username or Password</p>
          <p class="login-error-msg" *ngIf="showForgetPasswordMsg">{{validationMsg}}</p>
          <div class="input-div one">
            <div class="i">
              <i class="fas fa-edit"></i>
            </div>
            <div class="div">
              <input type="text" placeholder="First Name" class="input" formControlName="firstName">
              <hcn-validation-error [formControl]="signUpForm.get('firstName')" [validationErrors]="validationErrors.firstName"
                  [isFormSubmitted]="isFormSubmitted"></hcn-validation-error>
            </div>
          </div>
          <div class="input-div one">
            <div class="i">
              <i class="fas fa-edit"></i>
            </div>
            <div class="div">
              <input type="text" placeholder="Last Name" class="input" formControlName="lastName">
              <hcn-validation-error [formControl]="signUpForm.get('lastName')" [validationErrors]="validationErrors.lastName"
                  [isFormSubmitted]="isFormSubmitted"></hcn-validation-error>
            </div>
          </div>
          <div class="input-div one">
            <div class="i">
              <i class="fas fa-user"></i>
            </div>
            <div class="div">
              <input type="text" placeholder="User Name (Email)" class="input" formControlName="email">
              <hcn-validation-error [formControl]="signUpForm.get('email')" [validationErrors]="validationErrors.email"
                  [isFormSubmitted]="isFormSubmitted"></hcn-validation-error>
            </div>
          </div>
          <div class="input-div pass">
            <div class="i">
              <i class="fas fa-lock"></i>
            </div>
            <div class="div">
              <input [type]="hide ? 'password' : 'text'" placeholder="Password" class="input"
                formControlName="password">
              <hcn-validation-error [formControl]="signUpForm.get('password')"
                  [validationErrors]="validationErrors.password"
                  [isFormSubmitted]="isFormSubmitted"></hcn-validation-error>
            </div>
          </div>
          <div class="input-div pass">
            <div class="i">
              <i class="fas fa-lock"></i>
            </div>
            <div class="div">
              <input [type]="hide ? 'password' : 'text'" placeholder="confirm Password" class="input"
                formControlName="cnfirmPassword">
              <hcn-validation-error [formControl]="signUpForm.get('cnfirmPassword')"
                  [validationErrors]="validationErrors.cnfirmPassword"
                  [isFormSubmitted]="isFormSubmitted"></hcn-validation-error>
            </div>
          </div>
          <div>
            <span style="float: left;">
              <mat-checkbox color="primary" [(ngModel)]="hide"
                (change)="showHidePass()"> &nbsp;&nbsp;&nbsp;&nbsp;Show
                Password</mat-checkbox></span>
          </div>
          <span>
            <button style="margin-top: 25px" class="btn" (click)="signUp()">Submit</button>
          </span>
        </form>
      </div>
    </div>
  </div>
</div>