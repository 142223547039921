import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-rzpay-book-appointment',
  templateUrl: './rzpay-book-appointment.component.html',
  styleUrl: './rzpay-book-appointment.component.css'
})
export class RzpayBookAppointmentComponent implements OnInit  {

  safeURL
  constructor(private _sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<RzpayBookAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ){
   

   
  }
  ngOnInit(): void {
    this.safeURL= this._sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
