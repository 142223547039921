import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_URLS } from "../srhk.environment";
import { BasicInfoUpdate } from "../model/User";





@Injectable({
  providedIn: 'root'
})
export class RazorpayService {

  constructor(private http: HttpClient) { }

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic cnpwX2xpdmVfWGdjZjJEa1MybE9WVWE6VDJOalpUREtJeU5TYTBUaDZSZ2tyUVlW'
  });
  options = {
    headers: this.headers,
    Authorization: 'Basic cnpwX2xpdmVfWGdjZjJEa1MybE9WVWE6VDJOalpUREtJeU5TYTBUaDZSZ2tyUVlW'
  };

  public getPaymentDetails(id: string): any {
    return this.http.get<any>(APP_URLS.GET_RAZORPAYMENT.replace("{PAYMENT_ID}", id), { headers: this.headers });
  }
}

