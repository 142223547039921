import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthContext, AuthService, CommonCacheService, ToastService, TokenService } from '@srhk/srhk-core-library';
import { ToastrService } from 'ngx-toastr';
import { BookAppointmentService } from '../book-appointment/book-appointment.service';
import { UserService } from '../user.service';
import { MatDialog } from '@angular/material/dialog';
import { BillPaymentService } from '../billPayment.service';
import { LoggedInUserInfo } from '../../model/User';
import { BookAppointment } from '../../model/bookAppointment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { PatientAddress, PincodeDivisions } from '../../model/address';
import { HCNSnackbarService } from '../../shared-component/snackbar/snackbar.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.css'
})
export class UserProfileComponent implements OnInit {

  showError: boolean = false;
  hide: boolean = true;
  _loaderShow = false;
  userInfo: LoggedInUserInfo;
  basicData : BookAppointment = new BookAppointment();


  addressForm: FormGroup;
  stateCodevalues: any[] = [];
  districtCodeValues: any[] = [];

  addressData:Subject<any> = new Subject();
  searchPincodesData: any;
  pincodeDivisions: PincodeDivisions[] = [];
  filteredPincodeDivisions: PincodeDivisions[] = [];
  district:string;
  state:string;
  country:string;

  constructor(
    private authContext: AuthContext,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tokenService: TokenService,
    private commonCacheService: CommonCacheService,
    private toastService: ToastService,
    private toastr: ToastrService,
    private bookAppointmentService: BookAppointmentService,
    private userService: UserService,
    public dialog: MatDialog,
    public billPaymentService: BillPaymentService,
    private fb: FormBuilder,
    private hcnSnackbarService: HCNSnackbarService,
  ) {

    console.log("------this.authContext.isAuthenticated------", this.authContext.isAuthenticated)
    if (!this.authContext.isAuthenticated) {
      this.tokenService.setHomeDashboardURL('userProfile')
      this.router.navigate(['auth/login']);
    }
    if (this.tokenService.getHomeDashboardURL() !== 'userProfile') {
      this.tokenService.setHomeDashboardURL('userProfile')
    }
  }

  ngOnInit(): void {
    this.userInfo = this.tokenService.getUserInfo();
    this.setLoggedInUser(this.userInfo)


    const countryTypes = this.commonCacheService.cache ? this.commonCacheService.cache.countryTypes : [];
    this.stateCodevalues = countryTypes;
    // Pincode Divisions
    this.pincodeDivisions = this.commonCacheService.cache ? this.commonCacheService.cache.pincodeDivisions : [];

    if(this.userInfo.user.communicationId.internetAddresses.length > 0){
      this.createAddress(this.userInfo.user.communicationId.internetAddresses[0]);
    } else{
      this.createAddress(new PatientAddress());
    }
  
  }

  setLoggedInUser(userInfo) {
    this.basicData.firstName = userInfo.user.firstName;
    this.basicData.lastName = userInfo.user.lastName;
    this.basicData.phoneNumber = userInfo?.user?.communicationId?.telephones[0]?.dialNumber;
    this.basicData.userId = userInfo?.user?.communicationId?.emailIds[0]?.uri;
  }

  onStateChange(districts) {
    const obj = this.stateCodevalues.find(o => o.code === districts);
    this.addressForm.value['subDivision1'] = '';
    this.districtCodeValues = obj.subCodes;
  }
  
  onDistrictSelectFocus() {
    if (this.addressForm.value['subDivision2'] === '') {
      this.hcnSnackbarService.showSimpleSnackBar("Please select primary state Name", 3000);
    }
  }

  onPrimaryPincodeSelected(pincodeDivision: PincodeDivisions) {
    this.filteredPincodeDivisions = [];
    this.searchPincodesData = '';
    this.addressForm.controls['searchPincodesData'].setValue(null);
    this.addressForm.controls['lineOne'].setValue(pincodeDivision.circleName);
    this.addressForm.controls['lineTwo'].setValue(pincodeDivision.divisionName);
    this.addressForm.controls['lineThree'].setValue(pincodeDivision.officeName);
    const city = pincodeDivision.divisionName.replace(' Division', '');
    this.addressForm.controls['city'].setValue(city);
    this.onStateChange(pincodeDivision.stateCode);
    this.addressForm.controls['subDivision1'].setValue(pincodeDivision.districtCode);
    this.addressForm.controls['subDivision2'].setValue(pincodeDivision.stateCode);
    this.addressForm.controls['country'].setValue('IN');
    this.addressForm.controls['postalCode'].setValue(pincodeDivision.pincode);
  }

  onPincodeChange(search: string) {
    this.filteredPincodeDivisions = [];
    let pincodeData: PincodeDivisions[] = [];
    
    if (search.length > 3) {
      pincodeData = this.pincodeDivisions.filter(
        item => item.officeName.toLowerCase().includes(search.toLowerCase()) ||
          item.circleName.toLowerCase().includes(search.toLowerCase()) ||
          item.divisionName.toLowerCase().includes(search.toLowerCase()) ||
          item.pincode.includes(search)
      ).slice(0, 10);
    }

    pincodeData.map(item => {
      this.stateCodevalues.map(states => {
        if (states.code === item.stateCode) {
          states.subCodes.map(disctricts => {
            if (disctricts.code === item.districtCode) {
              item.disctrictFullName = disctricts.value;
            }
          });
          item.stateFullName = states.value;
        }
      });
    });
    this.filteredPincodeDivisions = pincodeData;
  }

  createAddress(address: PatientAddress) {

    if(address.subDivision2){
      this.onStateChange(address.subDivision2);
    }

    this.addressForm = this.fb.group({
      id: [address.id],
      searchPincodesData: [''],
      type: [address.type],
      lineOne: [address.lineOne],
      lineTwo: [address.lineTwo],
      lineThree: [address.lineThree],
      city: [address.city],
      subDivision1: [address.subDivision1],
      subDivision2: [address.subDivision2],
      country: [address.country],
      postalCode: [address.postalCode],
    });
  }

}
