<div id="contact">

    <form #ff="ngForm">
        <mat-card title="Address">
            <div class="row gy-12">
                <h3 style="color: #1975bc">Basic Details</h3>
                <p></p>

                <div class="row gy-4">
                    <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12">
                        <mat-label>First Name</mat-label>
                        <input matInput type="text" name="firstName" placeholder="First Name"
                            [(ngModel)]="basicData.firstName" required>
                        <mat-error>
                            First Name Cannot be empty!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12">
                        <mat-label>Last Name</mat-label>
                        <input matInput type="text" placeholder="Last Name" name="lastName"
                            [(ngModel)]="basicData.lastName" required>
                        <mat-error>
                            Last Name Cannot be empty!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12">
                        <mat-label>Email</mat-label>
                        <input matInput type="text" placeholder="Last Name" name="userId" [(ngModel)]="basicData.userId"
                            required>
                        <mat-error>
                            Email Cannot be empty!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-lg-3 col-md-3 col-sm-12">
                        <span matPrefix>+91 &nbsp;</span>
                        <mat-label>Phone Number</mat-label>
                        <input matInput type="text" placeholder="Phone Number" name="phoneNumber"
                            [(ngModel)]="basicData.phoneNumber" required>
                        <mat-error>
                            Phone Number Cannot be empty!
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-card>
    </form>
    <div id="contact">
        <form [formGroup]="addressForm">
            <mat-card>
                <h3 style="color: #1975bc">Communmication Details</h3>
                <input type="number" hidden="true" formControlName="id">
                <input type="text" hidden="true" formControlName="type">
                <div class="row gy-12">
                    <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                        <mat-label>Search for Area, Region, Postal Office & Pincode</mat-label>
                        <input formControlName="searchPincodesData"
                            placeholder="Search for Area, Region, Postal Office & Pincode..." matInput
                            [matAutocomplete]="pincodes" (input)="onPincodeChange($event.target.value)">
                        <mat-autocomplete #pincodes="matAutocomplete"
                            (optionSelected)="onPrimaryPincodeSelected($event.option.value)">
                            <mat-option *ngFor="let pincodeDivision of filteredPincodeDivisions"
                                [value]="pincodeDivision">
                                {{ pincodeDivision.divisionName + ', ' + pincodeDivision.officeName + ', ' +
                                pincodeDivision.disctrictFullName + ', ' + pincodeDivision.stateFullName + ', ' +
                                pincodeDivision.pincode }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error>
                            Postal Code cannot be empty or less than 6 characters!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                        <mat-label>Address Line 1</mat-label>
                        <input matInput type="text" placeholder="Address Line 1" formControlName="lineOne">
                        <mat-error>
                            Address Line 1 Cannot be empty!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                        <mat-label>Address Line 2</mat-label>
                        <input matInput type="text" placeholder="Address Line 2" formControlName="lineTwo">
                        <mat-error>
                            Address Line 2 Cannot be empty!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                        <mat-label> Address Line 3 </mat-label>
                        <input matInput type="text" placeholder="Address Line 3" formControlName="lineThree">
                        <mat-error>
                            Address Line 3 Cannot be empty!
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                        <mat-label>City</mat-label>
                        <input matInput type="text" placeholder="City" formControlName="city">
                        <mat-error>
                            City Cannot be empty!
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12"
                        [ngClass]="district?'colwithoutArrow':'col'">
                        <mat-label>District</mat-label>
                        <mat-select formControlName="subDivision1" (mouseenter)="onDistrictSelectFocus()"
                            placeholder="District" [(ngModel)]="district">
                            <mat-option *ngFor="let district of districtCodeValues; let j = index;"
                                [value]="district.code">
                                {{ district.value }}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            District Name cannot be empty!
                        </mat-error>
                        <button style="outline: none;" mat-button *ngIf="district" matSuffix mat-icon-button
                            aria-label="Clear" (click)="district= null; $event.stopPropagation();">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12"
                        [ngClass]="state?'colwithoutArrow':'col'">
                        <mat-label> State </mat-label>
                        <mat-select formControlName="subDivision2" (selectionChange)="onStateChange($event.value)"
                            placeholder="State" [(ngModel)]="state">
                            <mat-option *ngFor="let state of stateCodevalues;let j = index;" [value]="state.code">
                                {{ state.value }}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            State Name cannot be empty!
                        </mat-error>
                        <button style="outline: none;" mat-button *ngIf="state" matSuffix mat-icon-button
                            aria-label="Clear" (click)="state= null; $event.stopPropagation();">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12"
                        [ngClass]="country?'colwithoutArrow':'col'">
                        <mat-label>Country </mat-label>
                        <mat-select formControlName="country" placeholder="Country" [(ngModel)]="country">
                            <mat-option value="IN"> India </mat-option>
                        </mat-select>
                        <mat-error>
                            Country type cannot be empty!
                        </mat-error>
                        <button style="outline: none;" mat-button *ngIf="country" matSuffix mat-icon-button
                            aria-label="Clear" (click)="country= null; $event.stopPropagation();">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-lg-4 col-md-4 col-sm-12">
                        <mat-label>PostalCode</mat-label>
                        <input matInput maxlength="6" placeholder="Postal Code" formControlName="postalCode">
                        <mat-error>
                            Postal Code cannot be empty!
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-card>
        </form>
    </div>

</div>