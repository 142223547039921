import { Component } from '@angular/core';

@Component({
  selector: 'app-cancellation-and-refund',
  templateUrl: './cancellation-and-refund.component.html',
  styleUrl: './cancellation-and-refund.component.css'
})
export class CancellationAndRefundComponent {

}
