import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { AuthContext } from '../../shared/service/auth.context';
import { ActivatedRoute, Route, Router } from '@angular/router';
// import { AuthService } from '../../shared/service/auth.service';
// import { TokenService } from '../../shared/service/TokenService';
// import { CommonCacheService } from '../../shared/service/CommonCacheService';
import { SignUpService } from './singUp.service';
import { UserSignUp } from '../../model/UserSignUp';
import { AuthContext, AuthService, CommonCacheService, TokenService } from '@srhk/srhk-core-library';

@Component({
  selector: 'app-singUp',
  templateUrl: './singUp.component.html',
  styleUrls: [
    './singUp.component.scss',
  ],
})
export class SingUpComponent implements OnInit {
  signUpForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    cnfirmPassword: new FormControl('', [Validators.required]),
  });
  isFormSubmitted: boolean = false;
  showError: boolean = false;
  hide: boolean = true;
  _loaderShow = false;
  showForgetPasswordMsg = false;
  validationMsg = "";
  appType = "CUSTOMER";

  public validationErrors = {
    firstName: {
      'required': 'First name is required',
      'firstName': 'Invalid first Name',
    },
    lastName: {
      'required': 'Last name is required',
      'lastName': 'Invalid last Name',
    },
    email: {
      'required': 'User/Email name is required',
      'email': 'Invalid email',
    },
    password: {
      'required': 'Password is required',
    },
    cnfirmPassword: {
      'required': 'Confirm Password should be same as password',
      'cnfirmPassword': 'Confirm Password should be same as password'
    },
  };
  constructor(
    private authContext: AuthContext,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tokenService: TokenService,
    private commonCacheService: CommonCacheService,
    private signUpService: SignUpService,
  ) {
    if (this.authContext.isAuthenticated) {
      this.router.navigate(['app'], { replaceUrl: true });
    }

  }

  ngOnInit(): void {
    this.buildForm();
    console.log("------appType------", this.appType)

  }

  viewPassword() {
    this.hide = !this.hide;
  }

  singUp() {
    this.router.navigate(['singUp'], { replaceUrl: true });
  }

  private buildForm(): void {
    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      cnfirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      firstName: new FormControl('', [Validators.required, Validators.minLength(4)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });
  }



  public signUp(): void {
    console.log("-------------signUp---------")
    this.showError = false;
    const username: string = this.signUpForm.get('email').value;
    const password: string = this.signUpForm.get('password').value;
    const cnfirmPassword: string = this.signUpForm.get('cnfirmPassword').value;
    const firstName: string = this.signUpForm.get('firstName').value;
    const lastName: string = this.signUpForm.get('lastName').value;
    this.isFormSubmitted = true;

    if (cnfirmPassword !== password) {
      this.showError = true;
      this.validationMsg = "Confirm Password should be same as password";
      // this.signUpForm.get('cnfirmPassword').errors.push("Confirm Password should be same as password");
      console.log("-------------signUp---------", this.signUpForm.controls['cnfirmPassword'])
      this.signUpForm.controls.cnfirmPassword.setErrors({ incorrect: true, message: 'Please enter a 5 digit value' })
      this.isAnyValidationError("cnfirmPassword")
      return;
    }
    const body: UserSignUp = {
      roleName: 'CUSTOMER',
      permission: 'CUSTOMER',
      userId: username,
      password: password,
      active: true,
      emailVerified: true,
      phoneNumberVerified: true,
      user: {
        firstName: firstName,
        lastName: lastName,
        status: "active",
        communicationId: {
          emailIds: [
            {
              type: "p",
              uri: username,
              notificationIndicator: true
            }
          ],
        }
      },
    }
    this.signUpService.singUp(body).then(signUpRes => {
      console.log("User Registration successfully completed", signUpRes)
      // add toast

      //get AccessToken
      this.authService.getAccessToken(username, password).then(res => {
        if (res && res.jwt) {

          this.tokenService.saveToken(res.jwt);
          this.tokenService.saveRefreshToken(null);
          this.tokenService.saveAppId(this.appType);
          //get UserInfo after token
          this.authService.getUserInfo(res.jwt).then((userInfo: any) => {
            // get user resource after 

            if (userInfo) {
              this.tokenService.saveUserInfo(userInfo);
              this.commonCacheService.cache.loggedInUserInfo = userInfo;
              var homeResource = null; // set home dashboard
              var homeDashboardURL = '/app/dashboard';
              if (homeResource && homeResource.length > 0 && homeResource[0]['rsname'] && homeResource[0]['rsname'].includes('-')) {
                const homeDashboard = homeResource[0]['rsname'].split('-');
                homeDashboardURL = homeDashboard.length > 1 ? homeDashboard[1] : '/app';
              }
              this.tokenService.setHomeDashboardURL(homeDashboardURL);
              this.router.navigate([homeDashboardURL], { replaceUrl: true });
              this._loaderShow = false;
              this.authContext.setAuthenticated();
              window.location.reload();
            }
          });
        } else {
          this._loaderShow = false;
          this.showError = true;
        }
      })
        .catch((err) => {
          console.log(err);
          this._loaderShow = false;
          this.showError = true;
        });
    }).catch(error => {
      this._loaderShow = false;
      this.showError = true;
    });

    //   {
    //     "codeValue": "SUCCESS",
    //     "messageTxt": "User Registered successfully",
    //     "debugMessage": "",
    //     "action": "",
    //     "field": "",
    //     "data": null
    // }

    // if (this.signUpForm.valid) {
    //   this._loaderShow = true;
    //   window.sessionStorage.clear();
    //   this.tokenService.removeToken();
    //   this.tokenService.removeRefreshToken();

    //   //get AccessToken
    //   this.authService.getAccessToken(username, password, this.appType).then(res => {
    //     if (res && res.jwt) {

    //       this.tokenService.saveToken(res.jwt);
    //       this.tokenService.saveRefreshToken(null);
    //       this.tokenService.saveAppId(this.appType);
    //       //get UserInfo after token
    //       this.authService.getUserInfo(res.jwt).then((userInfo: any) => {
    //         // get user resource after 

    //         if (userInfo) {
    //           this.tokenService.saveUserInfo(userInfo);
    //           this.commonCacheService.cache.loggedInUserInfo = userInfo;
    //           var homeResource = null; // set home dashboard
    //           var homeDashboardURL = '/app/dashboard';
    //           if (homeResource && homeResource.length > 0 && homeResource[0]['rsname'] && homeResource[0]['rsname'].includes('-')) {
    //             const homeDashboard = homeResource[0]['rsname'].split('-');
    //             homeDashboardURL = homeDashboard.length > 1 ? homeDashboard[1] : '/app';
    //           }
    //           this.tokenService.setHomeDashboardURL(homeDashboardURL);
    //           this.router.navigate([homeDashboardURL], { replaceUrl: true });
    //           this._loaderShow = false;
    //           this.authContext.setAuthenticated();
    //           window.location.reload();
    //         }
    //       });
    //     } else {
    //       this._loaderShow = false;
    //       this.showError = true;
    //     }
    //   })
    //     .catch((err) => {
    //       console.log(err);
    //       this._loaderShow = false;
    //       this.showError = true;
    //     });
    // }
  }

  setContext(): void {
    this._loaderShow = false;
    this.authContext.setAuthenticated();
    this.router.navigate(['app'], { replaceUrl: true });
  }

  getFullYear() {
    return new Date().getFullYear();
  }

  showHidePass() {
    this.hide = !this.hide;
  }

  public isAnyValidationError(controlName: string): boolean {
    console.info(Object.keys(this.validationErrors[controlName]));
    const v = Object.keys(this.validationErrors[controlName]).some(k => this.signUpForm.controls[controlName].hasError(k));
    console.info(v);
    return v;
  }

  public getValidationError(controlName: string): string {
    const errors = this.signUpForm.controls[controlName].errors;
    if (errors != null) {
      const msg = this.validationErrors[controlName][Object.keys(errors)[0]];
      console.info(msg);
      return msg;
    }
    return '';
  }

}