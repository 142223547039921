import { Inject, Injectable, OnInit } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";
import { SnackbarComponent } from "./snackbar.component";

@Injectable({
  providedIn: "root",
})
export class HCNSnackbarService {
  constructor(
    private _snackBar: MatSnackBar,
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  showSimpleSnackBar(message: string, duration: number) {
    this._snackBar.open(message, null, {
      duration: duration,
      verticalPosition: "top",
      horizontalPosition: "right",
    });
  }

  showCustomSnackBar(customConfig: HcnSnackBarConfig) {
    this._snackBar.openFromComponent(SnackbarComponent, customConfig);
  }
}

export class HcnSnackBarConfig extends MatSnackBarConfig {
  declare horizontalPosition: "center" | "end" | "left" | "right" | "start";
  declare verticalPosition: "top" | "bottom";
  declare duration: number;
  declare panelClass: string | null;
  declare data: any;
}
