import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthContext, AuthService, CommonCacheService, ToastService, TokenService } from '@srhk/srhk-core-library';
import { ToastrService } from 'ngx-toastr';
import { BookAppointment } from '../../model/bookAppointment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { BookAppointmentService } from './book-appointment.service';
import { Order } from '../../model/order';
import { UserService } from '../user.service';
import { MatDialog } from '@angular/material/dialog';
import { BookAppointmentConfirmationComponent } from '../book-appointment-confirmation/book-appointment-confirmation.component';
import { RazorpayService } from '../razorPay.service';
import { Razorpayment } from '../../model/razorPayment';
import { BillPaymentService } from '../billPayment.service';
import { BasicInfoUpdate, LoggedInUserInfo } from '../../model/User';
import { CalculateOrder, CalculateOrderItem } from '../../model/calculateOrder';
import { AddCashReceipt } from '../../model/billCashReceipt';

declare var Razorpay: any

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrl: './book-appointment.component.css',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS,
    },]
})
export class BookAppointmentComponent implements OnInit {
  todayDate: Date = new Date();

  bookAppointment: BookAppointment = new BookAppointment();
  showError: boolean = false;
  hide: boolean = true;
  _loaderShow = false;

  constructor(
    private authContext: AuthContext,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tokenService: TokenService,
    private commonCacheService: CommonCacheService,
    private toastService: ToastService,
    private toastr: ToastrService,
    private bookAppointmentService: BookAppointmentService,
    private userService: UserService,
    public dialog: MatDialog,
    public razorpayService: RazorpayService,
    public billPaymentService: BillPaymentService,
  ) {

    console.log("------this.authContext.isAuthenticated------", this.authContext.isAuthenticated)
    // if (!this.authContext.isAuthenticated) {
    //   this.tokenService.setHomeDashboardURL('bookAppointment')
    //   // this.router.navigate(['auth/login']);
    // }
    // if(this.tokenService.getHomeDashboardURL() !== 'bookAppointment'){
    //   this.tokenService.setHomeDashboardURL('bookAppointment')
    // }
  }


  ngOnInit(): void {
    this.bookAppointment.amount = 5000;
    this.bookAppointment.discountAmount=2500
    const userInfo = this.tokenService.getUserInfo();
    console.log("------userInfo------", userInfo)
    // this.tokenService.setHomeDashboardURL('')
    this.setLoggedInUser(userInfo);
  }

  setLoggedInUser(userInfo) {
    this.bookAppointment.firstName = userInfo.user.firstName;
    this.bookAppointment.lastName = userInfo.user.lastName;
    this.bookAppointment.phoneNumber = userInfo?.user?.communicationId?.telephones[0]?.dialNumber;
    this.bookAppointment.userId = userInfo?.user?.communicationId?.emailIds[0]?.uri;
  }

  // submit() {
  //   const option = {
  //     key: "rzp_live_abScAGhLkYh003",   //"rzp_test_PoIes8NohBWQvb", // mfoFghpNmTU3MKN4lp6MLKwr
  //     amount: 250,
  //     name: this.bookAppointment.firstName + " " + this.bookAppointment.lastName,
  //     description: "payment for appointment",
  //     image: "",
  //     orderId: "1234567891210",
  //     handler: function (response) {
  //       console.log("222@@@@@@success--", response);
  //       this.razorpayService.getPaymentDetails(response.razorpay_payment_id)
  //         .subscribe((response: Razorpayment) => {
  //           console.log("----response------", response)
  //           this.billPaymentService.addCashReceipt().subscribe((response) => {
  //             this.tokenService.saveRefreshToken(response.orderNumber)
  //             this.router.navigate(['paymentConfirmation']);
  //           }, (error) => {
  //             console.log("----err------", error)
  //           })
  //         }, (err) => {
  //           console.log("----err------", err)
  //         })
  //     },
  //     prefill: {
  //       name: this.bookAppointment.firstName + " " + this.bookAppointment.lastName,
  //       email: this.bookAppointment.userId,
  //       contact: this.bookAppointment.phoneNumber
  //     },
  //     theme: {
  //       colour: "#3399cc"
  //     },
  //     modal: {
  //       ondismiss: function () {
  //         console.log('Checkout form closed');
  //       }
  //     }
  //   };
  //   var rzp1 = new Razorpay(option);
  //   rzp1.open()
  //   rzp1.on('payment.failed', function (response) {
  //     console.log("--failed-response----", response)
  //   })

  // }

  submit() {

    console.log("------bookAppointment------", this.bookAppointment);
    let id= null;
    
    // const BasicInfoUpdateBody: BasicInfoUpdate = {
    //   id: id,
    //   firstName: this.bookAppointment.firstName,
    //   lastName: this.bookAppointment.lastName,
    //   mobileNumber: this.bookAppointment?.phoneNumber,
    //   emailId: this.bookAppointment?.userId
    // }

    const calculateOrderItem: CalculateOrderItem = {
      id: null,
      name: "appointment",
      description: this.bookAppointment.purpose,
      image: null,
      sku: "Appointment",
      taxCategoryCode: "0",
      quantity: 1,
      productId: null,
      price: this.bookAppointment.amount,
      gst: null,
      discountAmount:2500,
    }
    const calculateOrder: CalculateOrder = {
      id: 0,
      orderNumber: null,
      orderItem: [calculateOrderItem],
      status: {
        id: null,
        name: "pending",
        createdDate: new Date(),
        updatedDate: new Date()
      },
      user: {
        firstName: this.bookAppointment.firstName,
        lastName: this.bookAppointment.lastName,
        status: "active",
        communicationId: {
          emailIds: [
            {
              type: "p",
              uri: this.bookAppointment?.userId,
              notificationIndicator: true,
              id: 0
            }
          ],
          telephones: [null],
          internetAddresses: [null]
        },
        id: 0,
        middleName: '',
        address: [null],
        createdDate: new Date(),
        updatedDate: new Date(),
      }
    }

    // this.userService.UpdateBasicInfo(BasicInfoUpdateBody).subscribe((user: LoggedInUserInfo) => {
console.log("-------BasicInfoUpdateBody-------")
      this.bookAppointmentService.calculate(calculateOrder).subscribe((order: Order) => {
        console.log("An order ", JSON.stringify(order));
        this.openOpDialog(order);
      }, (error) => {
        console.log("An error ocured", error);
        this._loaderShow = false;
        this.toastService.showError("An error ocured", "Error");
      })
    // }, (err) => {
    //   console.log("An error ocured", err);
    //   this._loaderShow = false;
    //   this.toastService.showError("An error ocured", "Error");
    // })



  }

  successPayment(response: any, order: Order, bookAppointmentSvc: BookAppointmentService,
    razorpaySvc: RazorpayService, billPaymentSvc: BillPaymentService,
    tokenSvc: TokenService, rvtr: Router
  ) {
    order.status.name = "success";
    order.trackingId = response.razorpay_payment_id;
    bookAppointmentSvc.placeOrder(order).subscribe(res => {
      order = res?.data;
      const token = tokenSvc.getToken();
      tokenSvc.saveToken("");
      razorpaySvc.getPaymentDetails(response.razorpay_payment_id)
        .subscribe((razorpaymentResponse: Razorpayment) => {
          console.log("----response------", response)
          order.status.name = "success";
          order.trackingId = response.razorpay_payment_id;

          const cashReceipt: AddCashReceipt = {
            transactionId: response?.razorpay_payment_id,
            orderNumber: order.orderNumber,
            amount: order.totalAmount,
            gst: order.gst,
            shippingCharge: 0,
            discount: 0,
            refNumber: response?.razorpay_payment_id,
            transactionDate: new Date(),
            paymentType: razorpaymentResponse.method,
            bankName: razorpaymentResponse.bank,
            bankAccount: null,
            bankStateBranch: null,
            notes: null,
            receivedDate: new Date(),
            paidByGuestEmail: null,
            paidByUserName: null
          }
          tokenSvc.saveToken(token);
          billPaymentSvc.addGuestCashReceipt(cashReceipt).subscribe((cashReceiptResponse) => {
            tokenSvc.saveRefreshToken(cashReceiptResponse.orderNumber)
            tokenSvc.setHomeDashboardURL('paymentConfirmation')
            rvtr.navigate(['paymentConfirmation']);
          }, (error) => {
            console.log("--1--err------", error)
          })
        }, (err) => {
          console.log("--2--err------", err)
        });
    }, (err) => {
      console.log("--3--err------", err)
    })
  }

  cancelledPayment(order: Order, bookAppointmentService: BookAppointmentService, toastSvc: ToastService) {
    bookAppointmentService.placeOrder(order).subscribe(res => {
      console.log("cancelledPayment  success", res)
      window.location.reload();
      toastSvc.showError("Payment Cancelled", "Error")
    }, (err) => {
      console.log("-cancelledPayment-err--", err)
      window.location.reload();
      toastSvc.showError("Payment Cancelled update", "Error")
    });
  }

  onFailedPayment(failedResponse: any, order: Order,
    bookAppointmentSvc: BookAppointmentService,
    toastSvc: ToastService,
    tokenSvc: TokenService,
    rvtr: Router
  ) {
    order.status.name = "failed";
    order.trackingId = failedResponse.error.metadata.payment_id;
    bookAppointmentSvc.placeOrder(order).subscribe(res => {
      tokenSvc.saveRefreshToken(order.orderNumber)
      tokenSvc.setHomeDashboardURL('paymentFailed')
      rvtr.navigate([`/paymentFailed`, { queryParams: { orderNumber: order.orderNumber } }]);
      // toastSvc.showError(failedResponse.error.description, failedResponse.error.step)
    }, (err) => {
      console.log("An Error", err)
    });
  }

  openOpDialog(order: Order): void {
    const dialogRef = this.dialog.open(BookAppointmentConfirmationComponent, {
      width: '550px',
      data: order,
    });


    dialogRef.afterClosed().subscribe((result: null) => {
      console.log('The dialog was closed', result);
      if (result === "proceed" && order) {
        this.bookAppointmentService.placeOrder(order).subscribe(res => {
          console.log(res);
          order = res.data;
          this.tokenService.saveUserInfo(order?.user);
          if (res) {
            const successPayment = this.successPayment;
            const cancelledPayment = this.cancelledPayment;
            const bookAppointmentSvc = this.bookAppointmentService;
            const onFailedPayment = this.onFailedPayment;
            const toastSvc = this.toastService;
            const razorpaySvc = this.razorpayService;
            const billPaymentSvc = this.billPaymentService;
            const tokenSvc = this.tokenService;
            const rvtr = this.router;

            const option = {
              key: "rzp_live_Xgcf2DkS2lOVUa", // "rzp_live_abScAGhLkYh003",   //"rzp_test_PoIes8NohBWQvb", // mfoFghpNmTU3MKN4lp6MLKwr
              amount: Number(res?.data.totalAmount) * 100,
              name: this.bookAppointment.firstName + " " + this.bookAppointment.lastName,
              description: this.bookAppointment.purpose,
              image: "",
              orderId: res?.data.orderNumber,
              handler: function (response) {
                console.log("222@@@@@@success--", response);
                successPayment(response, res?.data, bookAppointmentSvc, razorpaySvc,
                  billPaymentSvc, tokenSvc, rvtr
                )
              },
              prefill: {
                name: this.bookAppointment.firstName + " " + this.bookAppointment.lastName,
                email: this.bookAppointment.userId,
                contact: this.bookAppointment.phoneNumber
              },
              theme: {
                colour: "#3399cc"
              },
              modal: {
                ondismiss: function (this) {
                  console.log('Checkout form closed');
                  order.status.name = "cancelled";
                  cancelledPayment(order, bookAppointmentSvc, toastSvc)

                }
              }
            };
            var rzp1 = new Razorpay(option);
            rzp1.open();
            rzp1.on('payment.failed', function (failedResponse) {
              rzp1.close()
              onFailedPayment(failedResponse, order, bookAppointmentSvc, toastSvc, tokenSvc, rvtr)
            })
          }
        });
      }
    });
  }

}


