<div style="float: right;">
    <button class="close" mat-button (click)="onNoClick()">Close X</button>
</div>

<div>
    <mat-card title="guruMantraSadhana">
        <iframe [src]='safeURL' width="1224" height="712" frameborder="0" allowfullscreen></iframe>
    </mat-card>
    <br />
    <br />
</div>
      