<div>
    <br />
    <br />
    <b>Terms & Conditions</b>
    <br />
    <br />



    <p>The use of this site is provided by <b>Sri Rudramsha Hanuman Kshetram</b> and is subject to the following Terms and Conditions:</p>
    <br />
    <li>Your use of the site constitutes acceptance of these Terms and Conditions.</li>
    <br />
    <li>You agree to use this site only for lawful purposes, and in a manner which does not infringe the rights of <b>Sri Rudramsha Hanuman Kshetram</b>
        or any third party (including without limitation any <b>Sri Rudramsha Hanuman Kshetram</b> sponsor or beneficiary) or the use and enjoyment of
        the site by any third party.</li>
        <br />
    <li><b>Sri Rudramsha Hanuman Kshetram</b> shall in no event be liable for any damages including, without limitation, direct, indirect or
        consequential damages, whatsoever arising from or in connection with the use or loss of use of the site, or any
        content on the site.</li>
        <br />
    <li><b>Sri Rudramsha Hanuman Kshetram</b> can change these Terms and Conditions at any time by posting changes online under this section “Terms and
        Conditions”. Your continued use of this site after changes have been posted constitutes acceptance of the
        modified terms.</li>
        <br />
    <li><b>Sri Rudramsha Hanuman Kshetram</b> does not guarantee that the site’s facilities and functions will be free from error or uninterrupted, that
        the site or the server that makes it available to you are free of viruses or bugs, that defects will be
        corrected, or that the content on the site is accurate or complete.</li>
        <br />
    <li><b>Sri Rudramsha Hanuman Kshetram</b> takes no responsibility for the content of external Internet sites linked to or cited on this site.</li>
    <br />
    <li>Any communication or material that you transmit to, or post on any public area of this site will be treated as
        non-confidential and non-proprietary information unless otherwise stated by <b>Sri Rudramsha Hanuman Kshetram</b>. We reserve the right to
        remove without notice any material posted in any public area of the site.</li>
        <br />
    <li>These terms and conditions shall be governed by and construed in accordance with the laws of India. Any disputes
        shall be subject to the exclusive jurisdiction of the Courts of India.</li>
        <br />
    <li>If you do not accept these Terms and Conditions in full, you must stop using this site immediately. We reserve
        the right to prevent you accessing this site, without prejudice to any of our other rights, if we consider that
        you have contravened any of these Terms and Conditions.</li>

    
    <br />
    <br />
    <b>Disclaimers</b>
    <br />
    <br />
    <p>All the information on this website is published in good faith and for general information purpose only. We do not
    make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon
    the information on our website is strictly at your own risk. and we will not be liable for any losses and damages in
    connection with the use of our website.</p>
    <br />
    <p>From our website, you can visit other websites by following hyperlinks to these sites. While we strive to provide
    only links to useful and ethical websites, we have no control over the content and nature of these sites and the
    links to other websites do not imply a recommendation for all the content found on these sites.</p>
    <br />
    <p>Please be also aware that when you leave our website, other sites may have different privacy policies and terms
    which are beyond our control.</p>
    <br /><br />
</div>