<div>
    <div class="row">
        <div class="main--content col-md-12 col-sm-7" data-sticky-content="true">
            <div class="sticky-content-inner">
                <div class="row">
                    <div class="col-md-12 ptop--30  text-center">
                        <div class="post--items-title" data-ajax="tab">
                            <h2 class="h4">Our Asthana Gods</h2>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <img src="./assets/images/Ganesha.png" class="GuruGoraknath">
                                <h4 style="color: #da0000;"><a href="#">Lord Ganesha</a></h4>
                            </div>
                            <div class="col-md-4">
                                <img src="./assets/images/hanuman.jpeg" class="GuruGoraknath">
                                <h4 style="color: #da0000;"><a href="#">Lord Hanuman</a></h4>
                            </div>
                            <div class="col-md-4">
                                <img src="./assets/images/GuruGoraknath.jpeg" class="GuruGoraknath">
                                <h4 style="color: #da0000;"><a href="#">Guru Goraknath</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="main--content col-md-12 col-sm-7" data-sticky-content="true">
            <div class="sticky-content-inner">
                <div class="row">
                    <div class="col-md-12 ptop--30  text-center">
                        <div class="post--items-title" data-ajax="tab">
                            <h2 class="h4">Sri Rudramsha Hanuman Kshetram Mission</h2>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div style="border:1px solid #ff6600;border-radius:10px;margin:10px 0.5%;padding:10px;">
                                    <h3
                                        style="background-color:#ff7722;color:#ffffff;font-size:18px;font-weight:bold;text-align:center;">
                                        <a href="#" style="color:#ffffff;">About Us</a>
                                    </h3>
                                    <p style="font-size:16px;color:#2c3e50;text-align:justify;">The Sri Rudramsha
                                        Hanuman Kshetram are global spiritual organizations, devoid of political
                                        affiliations or sectarian biases.
                                        For over a century, they have been actively involved in a diverse range... <a
                                            href="about-ashrama" style="color:#ff7722;">Read
                                            More &gt;&gt;</a></p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div style="border:1px solid #ff6600;border-radius:10px;margin:10px 0.5%;padding:10px;">
                                    <h3
                                        style="background-color:#ff7722;color:#ffffff;font-size:18px;font-weight:bold;text-align:center;">
                                        <a href="#" style="color:#ffffff;">Ideology</a>
                                    </h3>
                                    <p style="font-size:16px;color:#2c3e50;text-align:justify;">The ideology of
                                        Sri Rudramsha Hanuman Kshetram consists of the eternal principles of
                                        Vedanta. This ideology has three characteristics: it is modern in the sense that
                                        the ancient
                                        principles of Vedanta expressed... <a href="#" style="color:#ff7722;">Read More
                                            &gt;&gt;</a></p>
                                </div>
                            </div>
                            <!-- <div class="col-md-3">
                                <div style="border:1px solid #ff6600;border-radius:10px;margin:10px 0.5%;padding:10px;">
                                    <h3
                                        style="background-color:#ff7722;color:#ffffff;font-size:18px;font-weight:bold;text-align:center;">
                                        <a href="#" style="color:#ffffff;">Emblem</a></h3>
                                    <p style="font-size:16px;color:#2c3e50;text-align:justify;">The emblem of the
                                        Ramakrishna Order designed by Swamij is a unique and unparalleled work of art
                                        created by one of the richest minds in contemporary history in an exalted mood
                                        of spiritual inspiration... <a href="emblem" style="color:#ff7722;">Read More
                                            &gt;&gt;</a></p>
                                </div>
                            </div> -->
                            <div class="col-md-4">
                                <div style="border:1px solid #ff6600;border-radius:10px;margin:10px 0.5%;padding:10px;">
                                    <h3
                                        style="background-color:#ff7722;color:#ffffff;font-size:18px;font-weight:bold;text-align:center;">
                                        <a href="#" style="color:#ffffff;">Activities</a>
                                    </h3>
                                    <p style="font-size:16px;color:#2c3e50;text-align:justify;">The ideology of
                                        Sri Rudramsha Hanuman Kshetram finds expression through their
                                        multifarious activities. These activities cover different areas of human need
                                        and social welfare such as education, ... <a href="#"
                                            style="color:#ff7722;">Read More &gt;&gt;</a></p>
                                </div>
                            </div>
                            <!-- Repeat for other sections -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="main--content col-md-12 col-sm-7" data-sticky-content="true">
            <div class="sticky-content-inner">
                <div class="post--items-title" data-ajax="tab">
                    <h2 class="h4">Our Mantra Sadhana Services</h2>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <mat-card class="example-card" appearance="outlined" style="border:1px solid #ff6600;">
                            <mat-card-header>
                                <div mat-card-avatar class="example-header-image"></div>
                                <mat-card-title>Guru Mantra Sadhana</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image src="./assets/images/GuruRaghavendraSwamy.jpg" alt="Photo of a Guru"
                                class="GuruGoraknath">
                            <mat-card-content>
                                <p>
                                    Guru Mantra Sadhana is indispensable in spiritual practice as it facilitates
                                    guidance, spiritual
                                    empowerment, purification of the mind, connection with spiritual lineage, and
                                    accelerated spiritual growth under
                                    the guidance and blessings of the Guru
                                </p>
                            </mat-card-content>
                            <mat-card-actions>
                                <button mat-flat-button color="primary" (click)="donation('Guru Mantra Sadhana')"
                                    style="margin: 6px; float: right;display: block;">Donate to access this service.</button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                    <div class="col-md-4">
                        <mat-card class="example-card" appearance="outlined" style="border:1px solid #ff6600;">
                            <mat-card-header>
                                <div mat-card-avatar class="example-header-image"></div>
                                <mat-card-title>Hanuman Mantra Sadhana</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image src="./assets/images/onLoading.png"
                                alt="Photo of Hanuman Mantra Sadhana" class="GuruGoraknath">
                            <mat-card-content>
                                <p>
                                    Hanuman mantra sadhana is a profound spiritual practice that not only seeks material
                                    benefits but also aims to cultivate virtues and deepen one's spiritual journey
                                    through the grace and blessings of Lord Hanuman.
                                </p>
                            </mat-card-content>
                            <mat-card-actions>
                                <button mat-flat-button color="primary" (click)="donation('Hanuman mantra sadhana')"
                                    style="margin: 6px; float: right;display: block;">Donate to access this service.</button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                    <div class="col-md-4">
                        <mat-card class="example-card" appearance="outlined" style="border:1px solid #ff6600;">
                            <mat-card-header>
                                <div mat-card-avatar class="example-header-image"></div>
                                <mat-card-title>Yantra Sadhana</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image src="./assets/images/yantra_image.webp" alt="Photo of a Yantra Sadhana"
                                class="GuruGoraknath">
                            <mat-card-content>
                                <p>
                                    Yatra Sadhana encompasses the spiritual journey of seeking divine blessings, self-transformation, and
                                    deeper spiritual realization. It is a profound practice aimed at harmonizing the
                                    mind, body, and spirit while fostering a deeper connection with the divine.
                                </p>
                            </mat-card-content>
                            <mat-card-actions>
                                <button mat-flat-button color="primary" (click)="donation('Yatra Sadhana')"
                                    style="margin: 6px; float: right;display: block;">Donate to access this service.</button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </div>
                <br />
            </div>
        </div>
    </div>

</div>