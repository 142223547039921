import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthContext, ToastService, TokenService } from '@srhk/srhk-core-library';

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrl: './home-dashboard.component.css'
})
export class HomeDashboardComponent implements OnInit{
  appType=""

  public constructor(private router: Router,
    private route: ActivatedRoute,
    private authContext: AuthContext,
    private dialog: MatDialog,
    private tokenService: TokenService,
    private toastService: ToastService
  ) {
    

  }

  ngOnInit(): void {
    console.log("------app---construction----")
    this.appType = this.route.snapshot.queryParamMap.get('appType') || 'CUSTOMER';
    this.tokenService.saveAppId(this.appType);
    this.tokenService.setHomeDashboardURL('')
  }

}
