import { Component } from '@angular/core';

@Component({
  selector: 'app-medical-information',
  templateUrl: './medical-information.component.html',
  styleUrl: './medical-information.component.css'
})
export class MedicalInformationComponent {

}
