import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { YOUTUBE_URLS } from '../youtube';

@Component({
  selector: 'app-guru-mantra-sadhana',
  templateUrl: './guru-mantra-sadhana.component.html',
  styleUrl: './guru-mantra-sadhana.component.css'
})
export class GuruMantraSadhanaComponent implements OnInit{

  videoUrl: string;
  safeURL
  constructor(private _sanitizer: DomSanitizer){
   
  }


  ngOnInit(): void {
    this.videoUrl = "https://www.youtube.com/embed/"+YOUTUBE_URLS.GURU_MANTRA_SADHANA
    this.safeURL= this._sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }

}
