export const YOUTUBE_URLS = {
    GURU_MANTRA_SADHANA: '_tQbcEQKb0E',
    MANTRA_SADHANA: '',
    YANTRA_SADHANA: '',
    Shri_Guru_Devatha_Abhishekam: '',
    Shri_Rudra_Devatha_Abhishekam:'',
    Sri_Kalarathri_Devatha_Abhishekam:'',
    Sri_Rudramsha_Hanuman_Devatha_Abhishekam:'',

    Shri_Guru_Devatha_Homam:'',
    Sri_Kalarathri_Devatha_Homam:'',
    Sri_Rudramsha_Hanuman_Devatha_Homam:'',
    Spl_Homam_Accomplishment:'',

    Coconut_Bali_Pooja:'',
    Lemon_Bali_Pooja:'',
    Pumpkin_Bali_Pooja:'',
    Rakhnaanna_Bali_for_Kali:'',
    Karya_Siddhi_Bali_Pooja:''

}