import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_URLS } from "../srhk.environment";
import { BasicInfoUpdate } from "../model/User";


const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

const options = {
  headers: headers,
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public UpdateBasicInfo(body: BasicInfoUpdate): any {
    return this.http.put<any>(APP_URLS.basicInfoUpdate, body, options);
  }
}

