import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { AuthContext } from '../shared/service/auth.context';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../features/logout/logout.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthContext, ToastService, TokenService } from '@srhk/srhk-core-library';
import { RzpayBookAppointmentComponent } from '../rzpay-book-appointment/rzpay-book-appointment.component';
// import { CommonCacheService } from '../shared/service/CommonCacheService';
// import { TokenService } from '../shared/service/TokenService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  durationInSeconds = 5;
  appType="";

  isAuthenticated: boolean = false;
  showOnloadSpinner: boolean = true;
  readonly dialog = inject(MatDialog);
  public constructor(private router: Router,
    private route: ActivatedRoute,
    private authContext: AuthContext,

    private tokenService: TokenService,
    private toastService: ToastService
  ) {
    

  }

  ngOnInit(): void {
    this.isAuthenticated = this.authContext.isAuthenticated;
    this.showOnloadSpinner = this.tokenService.getShowOnloadSpinner() === "true" ? true: false;
    this.tokenService.showOnloadSpinner("false");
  }

  logout() {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    this.tokenService.removeUserInfo();
    this.authContext.removeIsAuthenticated();
    window.sessionStorage.clear();
    this.toastService.showSuccess('Successfully logout', 'Success');
    this.router.navigate(["/"])
    window.location.reload();
    this.tokenService.showOnloadSpinner("false");
  }

  chooseLanguage(language: String){
    console.log("language", language);
  }

  donation(){
    const dialogRef = this.dialog.open(RzpayBookAppointmentComponent, {
      hasBackdrop: false,
      data: {
        url: 'https://rzp.io/rzp/LqrDvNE'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/']);
    });
  }

  navigateTo(path: string){
    if(path==='bookAppointment'){
        const dialogRef = this.dialog.open(RzpayBookAppointmentComponent, {
          hasBackdrop: false,
          data: {
            url: 'https://rzp.io/rzp/hVcNiWW'
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          this.router.navigate(['/']);
        });
      } else {
        this.router.navigate([path])
      }
   
  }

  homeNavigation() {
    this.router.navigate(['/']);
  }

  noticeNewsNavigation() {
    console.log("-------------noticeNewsNavigation------------")
    this.router.navigate(["/notice-news"])
  }

}
