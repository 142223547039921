export const APP_URLS = {
    getUser: '/v1/user/{userRoleId}',
    oauthToken: '/api/v1/user/signIn',
    oauthSignup: '/api/v1/user/signUp',
    loggedInUser: '/api/v1/user/loggedInUser',
    forget_password: '',
    calculate_order: '/api/v1/guest/calculate-bill',
    basicInfoUpdate: '/api/v1/user/basicInfo-update',
    ORDER_SAVE: '/api/v1/order/save',
    GUEST_ORDER_SAVE: '/api/v1/guest/order/save',
    GET_GUEST_CASH_RECEIPT: '/api/v1/guest/{ORDER_NUMBER}/all-cash-receipts',
    GET_ORDER: '/api/v1/order/orderNumber/{ORDER_NUMBER}',
    ADD_CASH_RECEIPT: '/api/bills/v1/{ORDER_NUMBER}/add-cash-receipt?guest=false',
    ADD_GUEST_CASH_RECEIPT: '/api/bills/guest/add-cash-receipt',
    GET_CASH_RECEIPT: '/api/bills/v1/{ORDER_NUMBER}/cash-receipts',
    GET_RAZORPAYMENT: '/api/v1/payments/{PAYMENT_ID}',
    LOGGED_IN_USER_ORDER: '/api/v1/order/loggedInUser/orders?pageNo=1&pageSize=1000',

}