import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_URLS } from "../../srhk.environment";
import { CalculateOrder } from "../../model/calculateOrder";
import { Order } from "../../model/order";


const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

const options = {
  headers: headers,
};

@Injectable({
  providedIn: 'root'
})
export class BookAppointmentService  {

  constructor(private http: HttpClient) { }
  
  findUserDetails(userId : string) : Observable<any> {

    let params: HttpParams = new HttpParams();
    params = params.set('userId', userId);
    return <any>this.http.get(APP_URLS.forget_password, { params: params, headers : headers });
  }

  public calculate(body: CalculateOrder) {
    return this.http.post<any>(APP_URLS.calculate_order, body, options);
  }

  public placeOrder(body: Order) {
    console.log("--------placeOrder---------")
    return this.http.post<any>(APP_URLS.GUEST_ORDER_SAVE, body, options);
  }

  public getOrder(orderNumber: string) {
    return this.http.get<any>(APP_URLS.GET_ORDER.replace("{ORDER_NUMBER}", orderNumber), options);
  }

  public getLoggedInUserOrder(){
    return this.http.get<any>(APP_URLS.LOGGED_IN_USER_ORDER, options);
  }


}

