import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthContext, AuthService, CommonCacheService, ToastService, TokenService } from '@srhk/srhk-core-library';
import { ToastrService } from 'ngx-toastr';
import { BookAppointmentService } from '../book-appointment/book-appointment.service';
import { UserService } from '../user.service';
import { MatDialog } from '@angular/material/dialog';
import { BillPaymentService } from '../billPayment.service';
import { FormBuilder } from '@angular/forms';
import { Order } from '../../model/order';

interface MyBookings {
  sno: number;
  orderNumber: string;
  type: string;
  amount: number;
  status: string;
  date: Date;
}

@Component({
  selector: 'app-user-booking',
  templateUrl: './user-booking.component.html',
  styleUrl: './user-booking.component.css'
})
export class UserBookingComponent implements OnInit {

  myBookinglist: MyBookings[] = [];
  displayedColumns: string[] = ['sno', 'orderNumber', 'firstName','lastName','formattedNumber','email', 'type', 'amount', 'status', 'date'];

  constructor(
    private authContext: AuthContext,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tokenService: TokenService,
    private commonCacheService: CommonCacheService,
    private toastService: ToastService,
    private toastr: ToastrService,
    private bookAppointmentService: BookAppointmentService,
    private userService: UserService,
    public dialog: MatDialog,
    public billPaymentService: BillPaymentService
  ) {

    console.log("------this.authContext.isAuthenticated------", this.authContext.isAuthenticated)
    if (!this.authContext.isAuthenticated) {
      this.tokenService.setHomeDashboardURL('userOrder')
      this.router.navigate(['auth/login']);
    }
    if (this.tokenService.getHomeDashboardURL() !== 'userOrder') {
      this.tokenService.setHomeDashboardURL('userOrder')
    }

  }

  ngOnInit(): void {
    this.getLoggedInUserOrder()
  }

  getLoggedInUserOrder() {
    this.bookAppointmentService.getLoggedInUserOrder().subscribe((response: any) => {
      console.log("An order ", JSON.stringify(response));

      const orderInfo: Order[] = response.data.orders;
      let snos = 0;
      const tempMyBookinglist = [];
      orderInfo.forEach((order) => {
        snos = snos + 1;
        const myBooking = {
          sno: snos,
          orderNumber: order.orderNumber.substring(24, 36),
          type: order.orderItem[0]?.sku,
          amount: order.totalAmount,
          status: order.status?.name,
          date: order.updatedDate,
          firstName:order?.user?.firstName,
          lastName: order?.user?.lastName,
          formattedNumber:order?.user?.communicationId?.telephones?.[0]?.formattedNumber,
          email: order?.user?.communicationId?.emailIds?.[0]?.uri

        }
        tempMyBookinglist.push(myBooking)
      })
      this.myBookinglist = [...tempMyBookinglist];
    }, (error) => {
      console.log("An error ocured", error);
      this.toastService.showError("An error ocured while fecting the details", "Error");
    })
  }
  
}
