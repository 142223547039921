import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_URLS } from "../../srhk.environment";


const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'App-ID' : 'HCN'
});

@Injectable({
  providedIn: 'root'
})
export class LoginService  {

  constructor(private http: HttpClient) { }
  
  findUserDetails(userId : string) : Observable<any> {

    let params: HttpParams = new HttpParams();
    params = params.set('userId', userId);
    return <any>this.http.get(APP_URLS.forget_password, { params: params, headers : headers });
  }
}

