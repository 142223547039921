<!-- <p>singup works!</p>
<mat-card>
    <div class="login-content">
        <form class="example-form">
            <mat-form-field appearance="outline" class="col">
                <mat-label>First Name</mat-label>
                <input maxlength="255" [(ngModel)]="userSignUp.user.firstName" matInput type="text"
                    placeholder="First Name" [(ngModel)]="userSignUp.user.firstName" [required]="true">
                <mat-error>
                    First Name Cannot be empty!
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>Last Name</mat-label>
                <input maxlength="255" [(ngModel)]="userSignUp.user.lastName" matInput type="text"
                    placeholder="First Name" [(ngModel)]="userSignUp.user.lastName" [required]="true">
                <mat-error>
                    Last Name Cannot be empty!
                </mat-error>
            </mat-form-field>


            <table class="example-full-width" cellspacing="0">
                <tr>
                    <td><mat-form-field class="example-full-width">
                            <mat-label>First name</mat-label>
                            <input matInput>
                        </mat-form-field></td>
                    <td><mat-form-field class="example-full-width">
                            <mat-label>Long Last Name That Will Be Truncated</mat-label>
                            <input matInput>
                        </mat-form-field></td>
                </tr>
            </table>

        </form>
        <div class="input-div one">
            <div class="i">
                <i class="fas fa-user"></i>
            </div>
            <div class="div">
                <input type="text" placeholder="Username" class="input" formControlName="email">

            </div>
        </div>
    </div>
</mat-card> -->
<!-- 
<div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      @if (!isSuccessful) {
      <form
        name="form"
        (ngSubmit)="f.form.valid && onSubmit()"
        #f="ngForm"
        novalidate
      >
        <div class="form-group">
          <label for="username">Username</label>
          <input
            type="text"
            class="form-control"
            name="username"
            [(ngModel)]="form.username"
            required
            minlength="3"
            maxlength="20"
            #username="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && username.errors }"
          />
          @if (username.errors && f.submitted) {
          <div class="invalid-feedback">
            @if (username.errors['required']) {
            <div>Username is required</div>
            }
            @if (username.errors['minlength']) {
            <div>Username must be at least 3 characters</div>
            }
            @if (username.errors['maxlength']) {
            <div>Username must be at most 20 characters</div>
            }
          </div>
          }
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            class="form-control"
            name="email"
            [(ngModel)]="form.email"
            required
            email
            #email="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
          />
          @if (email.errors && f.submitted) {
          <div class="invalid-feedback">
            @if (email.errors['required']) {
            <div>Email is required</div>
            }
            @if (email.errors['email']) {
            <div>Email must be a valid email address</div>
            }
          </div>
          }
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            class="form-control"
            name="password"
            [(ngModel)]="form.password"
            required
            minlength="6"
            #password="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
          />
          @if (password.errors && f.submitted) {
          <div class="invalid-feedback">
            @if (password.errors['required']) {
            <div>Password is required</div>
            }
            @if (password.errors['minlength']) {
            <div>Password must be at least 6 characters</div>
            }
          </div>
          }
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block">Sign Up</button>
        </div>
  
        @if (f.submitted && isSignUpFailed) {
        <div class="alert alert-warning">
          Signup failed!<br />{{ errorMessage }}
        </div>
        }
      </form>
      } @else {
      <div class="alert alert-success">Your registration is successful!</div>
      }
    </div>
  </div> -->
  
  <div class="login-container">
    <mat-spinner *ngIf="_loaderShow" class="tp-margin">
    </mat-spinner>
    <div>
      <!-- <img class="wave" src="../../../assets/images/wave_blue.png"> -->
      <div class="container">
        <!-- <div class="img">
            <img src="../../../assets/images/bg_new.svg">
          </div> -->
        <div class="singup-content">
          <form [formGroup]="userSignUpForm" *ngIf="userSignUpForm">
            <p class="login-error-msg" *ngIf="showError">Invalid Username or Password</p>
            <p class="login-error-msg" *ngIf="showForgetPasswordMsg">{{validationMsg}}</p>
            <div class="input-div one">
              <div class="i">
                <i class="fas fa-user"></i>
              </div>
              <div class="div">
                <input type="text" placeholder="Username" class="input" formControlName="email">
                <hcn-validation-error [formControl]="loginForm.get('email')" [validationErrors]="validationErrors.email"
                    [isFormSubmitted]="isFormSubmitted"></hcn-validation-error>
              </div>
            </div>
            <div class="input-div pass">
              <div class="i">
                <i class="fas fa-lock"></i>
              </div>
              <div class="div">
                <input [type]="hide ? 'password' : 'text'" placeholder="Password" class="input"
                  formControlName="password">
                <hcn-validation-error [formControl]="loginForm.get('password')"
                    [validationErrors]="validationErrors.password"
                    [isFormSubmitted]="isFormSubmitted"></hcn-validation-error>
              </div>
            </div>
            <div>
              <span style="float: left;">
                <mat-checkbox color="primary" [(ngModel)]="hide"
                  (change)="showHidePass()"> &nbsp;&nbsp;&nbsp;&nbsp;Show
                  Password</mat-checkbox></span>
            </div>
            <span>
              <button style="margin-top: 25px" class="btn" (click)="login()">Sign In</button>
              <button class="btn btn-sm btn-signup" (click)="singUp()" style="background-color: #da0000">Sign Up</button>
            </span>
            <a style="display: flex; justify-content: space-around;" (click)="forgetPassword()">Forgot Password?</a>
          </form>
        </div>
      </div>
    </div>
  </div>