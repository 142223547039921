import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { NoticeAndNewsComponent } from './notice-and-news/notice-and-news.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { AboutAshramaComponent } from './about-ashrama/about-ashrama.component';
import { VisionComponent } from './vision/vision.component';
import { ImportantVisitorComponent } from './important-visitor/important-visitor.component';
import { AuthenticationGuard } from './auth/guards';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LandingDashboardComponent } from './features/landing-dashboard/landing-dashboard.component';
import { FeaturesModule, MOMENT_DATETIME_WITH_SECONDS_FORMAT } from './features/features.module';
import { InspirationMissionComponent } from './inspiration-mission/inspiration-mission.component';
import { IdeologyComponent } from './ideology/ideology.component';
import { AuthModule } from './auth/auth.module';
import { SingupComponent } from './singup/singup.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import { TermAndConditionsComponent } from './term-and-conditions/term-and-conditions.component';
import { CancellationAndRefundComponent } from './cancellation-and-refund/cancellation-and-refund.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { MedicalInformationComponent } from './ashrama-activities/medical-information/medical-information.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from '@srhk/srhk-core-library';
import { GuruMantraSadhanaComponent } from './guru-mantra-sadhana/guru-mantra-sadhana.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NGX_MAT_DATE_FORMATS, NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatNativeDateModule } from '@angular/material/core';
import { RzpayBookAppointmentComponent } from './rzpay-book-appointment/rzpay-book-appointment.component';

const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: MOMENT_DATETIME_WITH_SECONDS_FORMAT,
  },
  display: {
    dateInput: MOMENT_DATETIME_WITH_SECONDS_FORMAT,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconButton,
    BrowserAnimationsModule,
    CommonModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    FeaturesModule,
    AuthModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule, 
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    RouterModule.forRoot([
      { path: '', component: HomeDashboardComponent },
      { path: 'home', component: HomeDashboardComponent },
      { path: 'notice-news', component: NoticeAndNewsComponent },
      { path: 'noticeNews', component: NoticeAndNewsComponent },
      { path: 'gallery', component: PhotoGalleryComponent },
      { path: 'about-ashrama', component: AboutAshramaComponent },
      { path: 'vision', component: VisionComponent },
      { path: 'singUp', component: SingupComponent },
      { path: 'important-visitor', component: ImportantVisitorComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'term-and-conditions', component: TermAndConditionsComponent },
      { path: 'cancellation-and-refund', component: CancellationAndRefundComponent },
      { path: 'contact', component: ContactInformationComponent },
      { path: 'medicalInfo', component: MedicalInformationComponent },
      { path: 'guruMantraSadhana', component: GuruMantraSadhanaComponent },
      { path: 'app', loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule) },
      { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
    ]),

    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeDashboardComponent,
    ValidationErrorComponent,
    InspirationMissionComponent,
    IdeologyComponent,
    SingupComponent,
    PrivacyPolicyComponent,
    TermAndConditionsComponent,
    CancellationAndRefundComponent,
    ContactInformationComponent,
    MedicalInformationComponent,
    GuruMantraSadhanaComponent,
    RzpayBookAppointmentComponent
  ],
  bootstrap: [
    AppComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    ValidationErrorComponent
],
providers: [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: SpinnerInterceptor,
  //   multi: true,
  // }
],

})
export class AppModule { }
