<h1 mat-dialog-title>Confirmation</h1>
<div mat-dialog-content>
  <p>"Are you ready to confirm your appointment and proceed with payment? Finalizing your booking ensures your slot is secured and everything is set for your convenience. Your prompt action allows us to manage our schedule effectively and provide you with seamless service. If you need assistance with payment or have any questions, feel free to contact us. "</p>
  <br/>
  <h3>Payment Details </h3>
  <div style=" display: ruby;">
    <p>Total Taxable Amount: <span style="font-weight:bold;">{{formatCurrency(order.totalTaxableAmount)}}</span>
      
  </div>
  <div>
    <p>GST Amount:   &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<span style="font-weight:bold;">{{formatCurrency(order.gst)}}</span>
      </p>
  </div>
  <div>
    <p>Total Net Amount:  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;<span style="font-weight:bold;">{{formatCurrency(order.totalAmount)}}</span></p>
  </div>
</div>
<div mat-dialog-actions >
  <button mat-button (click)="onNoClick()" style="margin: 6px; float: right;display: block;">Cancel</button>
  <button mat-flat-button color="primary" style="margin: 6px; float: right;display: block;" (click)="onYesClick()">Proceed for Payment</button>
</div>