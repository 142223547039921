<div class="main-content--section pbottom--30">
    <div class="container">
        <div class="post--item post--single pd--30-0">
            <div class="row">
                <div class="col-md-12">
                    <div class="post--info">
                        <div class="title">
                            <h2 class="h4"> The Notice and News</h2>
                        </div>
                    </div>
                    <div class="post--content">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card shadow mb-4">

                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table class="table table-bordered" id="dataTable" width="100%"
                                                cellspacing="0">
                                                <thead>

                                                    <tr>
                                                        <th rowspan="3" width="10%"
                                                            style="text-align: center; background-color: #da0000; vertical-align: middle;">

                                                            <div>
                                                                <font size="+1" color="#FFF">08 April</font>
                                                            </div>
                                                            <div>
                                                                <font size="+1" color="#FFF">2024</font>
                                                            </div>

                                                        </th>

                                                        <th style="vertical-align: middle;">
                                                            <h4>Mantra Diksha for Devotees on 9 April, 2024</h4><span
                                                                class="engraved">From : HEAD OFFICE</span>
                                                        </th>
                                                    </tr>

                                                    <tr>
                                                        <td style="vertical-align: middle;">Mantra Diksha for Devotees
                                                            on 9 April, 2024<br>
                                                            <br>
                                                            Dear Friends and Well wishers,<br>
                                                            It is is with great pleasure that all of you are informed
                                                            that Shrimat Swami Veerat Maharaj, Vice President of
                                                            Sri Rudramsha Hanuman Kshetram, will land on April 8, 2024 at
                                                            Sevashram, Hyderabad. On this
                                                            occasion, on April 9, 2023, he will grace the devotees by
                                                            giving mantra initiation in the morning. Devotees who want
                                                            to take Mantra Diksha should come here and meet at the
                                                            Ashram office (9:00 am - 1:30 pm, 3:00 pm - 5:00 pm) and
                                                            receive the form for Mantr Diksha or contact at given office
                                                            number for further details.<br>
                                                            <br>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="vertical-align: middle; text-align:center;"><i>
                                                                <font color="red"></font>
                                                            </i></td>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>