import { Component, OnInit } from '@angular/core';
import { LoggedInUserInfo, User } from '../../model/User';
import { ToastrService } from 'ngx-toastr';
import { AuthContext, AuthService, CommonCacheService, ToastService, TokenService } from '@srhk/srhk-core-library';
import { ActivatedRoute, Router } from '@angular/router';
import { BillPaymentService } from '../billPayment.service';
import { AddCashReceipt } from '../../model/billCashReceipt';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrl: './payment-confirmation.component.scss'
})
export class PaymentConfirmationComponent implements OnInit {

  userInfo: LoggedInUserInfo;
  paymentDetails: AddCashReceipt;

  constructor(
    private authContext: AuthContext,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tokenService: TokenService,
    private commonCacheService: CommonCacheService,
    private toastService: ToastService,
    private toastr: ToastrService,
    public billPaymentService: BillPaymentService,
  ) {
    // if (!this.authContext.isAuthenticated) {
    //   this.router.navigate(['auth/login']);
    // }
  }

  ngOnInit(): void {
    this.userInfo = this.tokenService.getUserInfo();
    this.getPaymentDetails(this.tokenService.getRefreshToken());
  }


  getPaymentDetails(orderNumber: string) {
    console.log("----orderNumber----", orderNumber);
    this.billPaymentService.getGuestCashReceipt(orderNumber).subscribe((res) => {
      this.paymentDetails = res[0];
      this.tokenService.saveRefreshToken("");
    }, (err) => {
      console.log("----err----", err);
      this.router.navigate(['home']);
      this.toastService.showError("An Error Occured while showing the confirmation", "Error")
    })

  }


}
