
export class BookAppointment {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    purpose: string;
    userId: string;
    appoinmentDateTime: Date;
    amount: number;
    discountAmount?: number;
}

